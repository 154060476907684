<template>
  <div class="container zoom">
    <div class="row">
      <div class="col-12">
        <h3 class="page-title">{{ $t('headermenu.connections') }}</h3>
      </div>
    </div>
    <div class="row" v-if="alert.status">
      <div class="col-12">
        <div class="alert alert-danger">
          {{ alert.message }}
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-12">
        <keep-alive>
          <b-tabs
            v-model="tabIndex"
            content-class="mt-3 w-100"
            class="connections__tabs"
          >
            <b-tab :title="$t('buttons.following')" class="w-100">
              <b-row
                class="table-columns "
                style="margin-top: 0px; margin-bottom:10px;"
              >
                <div class="list-header-mycon d-flex w-100">
                  <b-row class="d-flex w-100" style="margin-left:16px;">
                    <b-col cols="3">
                      <span>{{$t("my-connections.contact")}}</span>
                    </b-col>
                    <b-col cols="3" style="margin-left:-9px;">
                      <span>{{$t("my-connections.location")}}</span>
                    </b-col>
                    <b-col cols="2" style="margin-left:-12px;" offset-sm1="1">
                      <span>{{$t("my-connections.title")}}</span>
                    </b-col>
                    <b-col cols="2" style="margin-left:0px;">
                      <span>{{$t("my-connections.company")}}</span>
                    </b-col>
                  </b-row>
                </div>

                <b-col cols="1"> </b-col>
              </b-row>

              <div v-if="loading" class="mt-5 position-relative " >
                <loading :loading="loading" />
              </div>
              <b-overlay
                v-if="tabIndex === 0 && !loading"
                bg-color="#E5E5E5"
                :show="selectIndex.includes(index)"
                rounded
                opacity="0.9"
                v-for="(item, index) in list"
                :key="index"
                spinner-small
                spinner-variant="gray"
                class="w-100"
                z-index="9999"
              >
                <div class="row align-items-center connections-list-item">
                  <div class="col-3 avatar d-flex align-items-center">
                    <img :src="getAvatar(item.avatar, 64)" alt="" width="64" height="64"/>
                    <h3 class="text-capitalize ml-3 mb-0 pb-0">
                      <a @click.prevent="getUserProfile(item.uid)" href="#">
                        {{ shorter(item.name,"") }} {{ item.surname }}
                      </a>
                    </h3>
                  </div>
                  <div class="col-3 location d-flex align-items-center">
                    <div class="icon">
                      <v-icon :icon="['fac', 'location']" />
                    </div>
                    <span
                      >
                        {{ item.locations.find(x => x.default).address.city || 'unnamed' }},
                        {{
                            item.locations.find(x => x.default).address.country
                        }}
                      </span
                    >
                  </div>
                  <div class="col position">
                    <span>
                      {{ item.title.name }}
                    </span>
                  </div>
                  <div class="col company_name d-flex justify-content-between">
                    <span class="text-secondary">
                      {{ item.company.name }}
                    </span>
                  </div>
                  <div
                    class="col d-flex justify-content-between align-items-center"
                  >
                    <button
                      style="width:120px"
                      @mouseover="
                        isHoveringFollowBtnFollowing = true;
                        hoverItem = index;
                      "
                      @mouseout="
                        isHoveringFollowBtnFollowing = false;
                        hoverItem = null;
                      "
                      @click.prevent="unfollowUser(item.uid, index)"
                      class="btn btn-outline-primary btn-sm"
                      :class="{
                        'btn-outline-danger':
                          isHoveringFollowBtnFollowing && hoverItem === index,
                      }"
                    >
                      {{
                        !isHoveringFollowBtnFollowing && hoverItem === index
                          ? 'Following'
                          : 'Unfollow'
                      }}
                    </button>
                    <b-dropdown
                      right
                      :ref="'dropdown' + index"
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template #button-content class="p-0 color-gray">
                         <img src="/img/open-card-footer-menu.svg" alt=""> 
                        <!-- <v-icon :icon="['fas', 'ellipsis-v']"></v-icon> -->
                      </template>
                      <b-dropdown-item @click="startConversation(item.uid)" class="my-1">
                        <div class="d-flex align-items-center">
                          <div class="icon mr-3">
                            <v-icon :icon="['fac', 'edit']" />
                          </div>
                          <span>{{ $t('buttons.message') }}</span>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-divider />
                      <b-dropdown-item @click="block(item.uid)">
                        <div class="user-list-ban d-flex align-items-center">
                          <div class="icon mr-3">
                            <v-icon :icon="['fas', 'user-lock']" />
                          </div>
                          <span>{{ $t('buttons.block') }}</span>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </b-overlay>
            </b-tab>

            <b-tab :title="$t('buttons.followers')" class="w-100">
              <b-row
                class="table-columns "
                style="margin-top: 0px; margin-bottom:10px;"
              >
                <div class="list-header-mycon d-flex w-100">
                  <b-row class="d-flex w-100" style="margin-left:16px;">
                    <b-col cols="3">
                      <span>{{$t("my-connections.contact")}}</span>
                    </b-col>
                    <b-col cols="3" style="margin-left:-9px;">
                      <span>{{$t("my-connections.location")}}</span>
                    </b-col>
                    <b-col cols="2" style="margin-left:-12px;" offset-sm1="1">
                      <span>{{$t("my-connections.title")}}</span>
                    </b-col>
                    <b-col cols="2" style="margin-left:0px;">
                      <span>{{$t("my-connections.company")}}</span>
                    </b-col>
                  </b-row>
                </div>

                <b-col cols="1"> </b-col>
              </b-row>
              <loading :loading="loading" />
              <b-overlay
                v-if="tabIndex === 1 && !loading"
                bg-color="#E5E5E5"
                :show="selectIndex.includes(index)"
                rounded
                opacity="0.9"
                v-for="(item, index) in list"
                :key="index"
                spinner-small
                spinner-variant="gray"
                class="w-100"
                z-index="9999"
              >
                <div
                  v-if="tabIndex === 1"
                  class="row align-items-center connections-list-item"
                >
                  <div class="col-3 avatar d-flex align-items-center">
                    <img :src="getAvatar(item.avatar, 64)" alt="" width="64" height="64"/>
                    <h3 class="text-capitalize ml-3 mb-0 pb-0">
                      <a href="#" @click.prevent="getUserProfile(item.uid)">
                         {{ shorter (item.name  ,item.surname) }}
                      </a>
                    </h3>
                  </div>
                  <div class="col-3 location d-flex align-items-center">
                    <div class="icon">
                      <v-icon :icon="['fac', 'location']" />
                    </div>
                    <span
                      >{{ item.locations.find(x => x.default).address.city || 'unnamed' }},{{
                        item.locations.find(x => x.default).address.country
                      }}</span
                    >
                  </div>
                  <div class="col position">
                    <span v-if="item.position">
                      {{ item.position.name }}
                    </span>
                    <span v-if="!item.position">
                      {{ item.title.name }}
                    </span>
                  </div>
                  <div class="col company_name d-flex justify-content-between">
                    <span class="text-secondary" >
                      {{ item.company.name }}
                    </span>
                  </div>
                  <div
                    class="col d-flex justify-content-between align-items-center"
                  >
                    <button
                      style="width:120px"
                      :class="{ 'btn-outline-danger': isHoveringFollowBtnFollowing }"
                      @mouseover="hoverItemFunc(item.following, index)"
                      @mouseout="unHoverFunc"
                      @click="followUserFollowers(item.uid, item.following, index)"
                      class="btn btn-outline-primary btn-sm"
                    >
                      {{
                        item.following &&
                        !isHoveringFollowBtnFollowing &&
                        hoverItem === index
                          ? 'Following'
                          : item.following &&
                            isHoveringFollowBtnFollowing &&
                            hoverItem === index
                          ? 'Unfollow'
                          : 'Follow'
                      }}
                    </button>
                    <b-dropdown
                      right
                      :ref="'dropdown' + index"
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template #button-content class="p-0 color-gray">
                        <v-icon :icon="['fas', 'ellipsis-v']"></v-icon>
                      </template>
                      <b-dropdown-item @click="startConversation(item.uid)" class="my-1">
                        <div class="d-flex align-items-center">
                          <div class="icon mr-3">
                            <v-icon :icon="['fac', 'edit']" />
                          </div>
                          <span>{{ $t('buttons.message') }}</span>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-divider />
                      <b-dropdown-item @click="block(item.uid)">
                        <div class="user-list-ban d-flex align-items-center">
                          <div class="icon mr-3">
                            <v-icon :icon="['fas', 'user-lock']" />
                          </div>
                          <span>{{ $t('buttons.block') }}</span>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </b-overlay>
            </b-tab>

            <b-tab :title="$t('buttons.blocked')" class="w-100" style="justify-content:flex-start; width:170px">
              <b-row
                class="table-columns"
                style="margin-top: 0px; margin-bottom:10px;"
              >
                <div class="list-header-mycon d-flex w-100" v-if="list.length > 0">
                  <b-row class="d-flex w-100" style="margin-left:16px;">
                    <b-col cols="3">
                      <span>{{$t("my-connections.contact")}}</span>
                    </b-col>
                    <b-col cols="3" style="margin-left:-9px;">
                      <span>{{$t("my-connections.location")}}</span>
                    </b-col>
                    <b-col cols="2" style="margin-left:-12px;" offset-sm1="1">
                      <span>{{$t("my-connections.title")}}</span>
                    </b-col>
                    <b-col cols="2" style="margin-left:0px;">
                      <span>{{$t("my-connections.company")}}</span>
                    </b-col>
                  </b-row>
                </div>

                <b-col cols="1"> 
                    
                </b-col>
              </b-row>
              <loading :loading="loading" />
              <b-overlay
                v-if="tabIndex === 2 && !loading"
                bg-color="#E5E5E5"
                :show="selectIndex.includes(index)"
                rounded
                opacity="0.9"
                v-for="(item, index) in list"
                :key="index"
                spinner-small
                spinner-variant="gray"
                class="w-100"
                z-index="9999"
              >
                <div
                  v-if="tabIndex === 2"
                  class="row align-items-center connections-list-item"
                >
                  <div class="col-3 avatar d-flex align-items-center">
                    <img :src="getAvatar(item.avatar, 64)" alt="" width="64" height="64" />
                    <h3 class="text-capitalize ml-3 mb-0 pb-0">
                      <a href="#" @click.prevent="getUserProfile(item.uid)">
                         {{ shorter (item.name  ,item.surname) }}
                      </a>
                    </h3>
                  </div>
                  <div class="col-3 location d-flex align-items-center">
                    <div class="icon">
                      <v-icon :icon="['fac', 'location']" />
                    </div>
                    <span
                      >{{ item.locations.find(x => x.default).address.city || 'unnamed' }},{{
                        item.locations.find(x => x.default).address.country
                      }}</span
                    >
                  </div>
                  <div class="col position">
                    <span v-if="item.position">
                      {{ item.position.name }}
                    </span>
                    <span v-if="!item.position">
                      {{ item.title.name }}
                    </span>
                  </div>
                  <div class="col company_name d-flex justify-content-between">
                    <span class="text-secondary" >
                      {{ item.company.name }}
                    </span>
                  </div>
                  <div
                    class="col d-flex justify-content-between align-items-center"
                  >
                    <button
                      class="btn btn-outline-primary btn-sm"
                      @click="unblock(item.uid)"
                    >
                      {{$t('buttons.unblock')}}
                    </button>
                  </div>
                </div>
              </b-overlay>
            </b-tab>

            <template #tabs-end>
              <div class="connections__tabs__input-wrapper">
                <b-form-input
                  v-model="filters.search"
                  @keydown="searchOnKeyDown"
                  class="nav-item"
                  type="text"
                />
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                </svg>
              </div>
            </template>
          </b-tabs>
        </keep-alive>
      </div>
    </div>

    <b-row class="gridview-pagination" v-if="!loading">
      <b-col cols="12">
        <b-pagination
          v-if="$_.size(list) > 0"
          class="mt-4"
          align="center"
          v-model="page"
          pills
          :total-rows="total"
          :per-page="limit"
          @change="onPageChange()"
        >
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GET_CONNECTIONS from '@/graphql/me/myConnections.graphql';
import BLOCK  from '@/graphql/user/block.graphql';
import UNBLOCK  from '@/graphql/user/unblock.graphql';
import BANNEDS from "@/graphql/me/banneds.graphql";
import _ from 'lodash';
export default {
  name: 'connections',
  data() {
    return {
      isHoveringFollowBtnFollowing: false,
      selectIndex: [],
      alert: {
        status: false,
        message: null,
      },
      hoverItem: null,
      total: 0,
      loading: false,
      tabIndex: 0,
      filters: {
        type: 'FOLLOWINGS',
        search: null,
      },
      page: 1,
      limit: 20,
      list: [],
      searchOnKeyDown: null,
    };
  },
  watch: {
    tabIndex(val) {
      this.$set(this, 'list', []);
      this.page = 1;

      if (val === 0) {
        this.filters.type = 'FOLLOWINGS';
      }
      if (val === 1) {
        this.filters.type = 'FOLLOWERS';
      }

      if (val === 2) {
        this.filters.type = 'BANNEDS';
      }

      this.getList();
    },
  },
  methods: {
    unHoverFunc() {
      this.hoverItem = null;
      this.isHoveringFollowBtnFollowing = false;
    },
    hoverItemFunc(isFollowing, index) {
      if (isFollowing) {
        this.hoverItem = index;
        return (this.isHoveringFollowBtnFollowing = true);
      }
      this.isHoveringFollowBtnFollowing = false;
    },
    async followUserFollowers(member_id, is_following, index) {
      if (is_following) {
        return await this.unfollowUser(member_id, index);
      }
      return await this.followUser(member_id, index);
    },
    async followUser(member_id, index) {
      this.selectIndex.push(index);
      let payload = {
        uid: member_id,
      };
      let response = await this.$store.dispatch('user/followUser', {
        vm: this,
        payload,
      });
      if (response) {
        this.selectIndex = [];
        if (this.tabIndex === 0) {
          this.list = this.list.filter(item => item.uid !== member_id);
        }
        if (this.tabIndex === 1) {
          this.$nextTick(() => {
            this.list[index].following = true;
          });
        }
        return this.$bvToast.toast(this.$t("Toaster.followed"), {
          title: this.$t("Toaster.success-title"),
          variant: 'success',
          aautoHideDelay: 1350,
        });
      }
    },
    async unfollowUser(member_id, index) {
      this.selectIndex.push(index);
      let payload = {
        uid: member_id,
      };
      let response = await this.$store.dispatch('user/unfollowUser', {
        vm: this,
        payload,
      });
      if (response) {
        this.selectIndex = [];
        if (this.tabIndex === 0) {
          this.list = this.list.filter(item => item.uid !== member_id);
        }
        if (this.tabIndex === 1) {
          this.$nextTick(() => {
            this.list[index].following = false;
          });
        }
        return this.$bvToast.toast(this.$t("Toaster.unfollowed"), {
          title: this.$t("Toaster.success-title"),
          variant: 'success',
          autoHideDelay: 1350,
        });
      }
    },
    async getList() {
      try {
        this.loading = true;
        if (this.filters.type === 'BANNEDS') {
            let params = {
                filters: { search: this.filters.search },
                limit: this.limit,
                page: this.page,
            };

            let selector = await this.$apollo.query(BANNEDS, params);
            let response = selector('**.list');
            this.total = response.total || 0;
            this.$set(this, 'list', response.list);
            return;    
        }

        let params = {
            filters: this.filters,
            limit: this.limit,
            page: this.page,
        };

        let selector = await this.$apollo.query(GET_CONNECTIONS, params);
        let response = selector('**.list');
        this.total = response.total || 0;
        this.$set(this, 'list', response.list);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async block(uid) {
        await this.$apollo.mutate(BLOCK, { uid });
        this.list.splice(_.findIndex(this.list, { uid }), 1); 
    },

    async unblock(uid) {
        await this.$apollo.mutate(UNBLOCK, { uid });
        this.list.splice(_.findIndex(this.list, { uid }), 1); 
    },

    onPageChange() {
      this.$nextTick()
        .then(() => this.getList())
        .then(() => this.appScrollTop());
    },
  },
  created() {
    this.searchOnKeyDown = _.debounce(() => this.getList(), 1000);
    this.getList();
  },
};
</script>
